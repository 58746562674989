.portadaInicial {
    background-color: var(--color-base);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    font-family: 'Asap Condensed', sans-serif;
    justify-content: space-around;
}

.imgLogo{
    width: 150px;
    margin-bottom: 2rem;
}
.tituloPrincipal {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    font-size: 2rem;
    font-weight: 600;
    color: white;
}
.botonesInicio {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 3rem 0;    
}
.botonVerificar {
    width: 10rem;
    height: 3rem;
    background-color: #FF8000;
    border: 10px;
    border-color: white;
    border-radius: 5px;
    font-size: 25px;
    font-weight: 600;
    color: var(--color-base);
    box-shadow: 3px 3px 5px black;
}
.ingresoDni {
    font-size: 1.5rem;
    color: white;
    text-align: center;
}
.input{
    padding: 5px;
    width: 250px;
    text-align: center;
    font-size: 2rem;
    font-stretch: extra-expanded;
}
.modal {
    width: 100%;
    height: 100%;
    align-items: center;
}
.modal-content {
    background-image: url(/public/img/fondo_card.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}
.btn-close {
    background-color: #FF8000;
}
.modal-foto-dato {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    text-shadow: #FF8000;
    margin: 1.5rem 0 2.5rem 0;
}
.imgPersonaModal{
    width: 8rem;
}
.modal-apellido {
    font-size: 1.6rem;
    font-weight: 600;
    text-shadow: 1px 2px 2px black;
}
.modal-nombre {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    text-shadow: 1px 2px 2px black;
} 
.modal-dni {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--color-pelota);
}
.ceroResultados{
    color: white;
    width: 90%;
    margin-left: 5%;
    text-align: center;
}
.nombreModal-funcion {
    font-size: 1.2rem;
    font-weight: 650;
    text-align: center;
    color: var(--color-pelota);
}
.niveles {
    margin: 6rem 0 3rem 0;
}
.nivelVerde{
    background-color: var(--color-base);
    margin: 10px;
    padding: 0.7rem;
    font-size: 2rem;
    color: white;
    font-weight: bold;
    border: 8px solid rgb(12, 209, 12);
}
.nivelRojo{
    background-color: var(--color-base);
    margin: 10px;
    padding: 0.7rem;
    font-size: 2rem;
    color: white;
    font-weight: bold;
    border: 8px solid rgb(248, 19, 19);
}